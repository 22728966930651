import React from "react";
import Seo from "src/components/Seo";
import icon from "src/images/404-icon.svg";

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
  if (!browser) return null;

  return (
    <div>
      <Seo pageTitle="404: Страница не найдена" />
      <div
        style={{
          margin: "auto",
          display: "flex",
          height: "60vh",
        }}
      >
        <div
          style={{
            margin: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ marginRight: "24px", width: "250px" }}>
            <img src={icon} alt="Страница не найдена" />
          </div>
          <div style={{}}>
            <h1
              style={{
                fontSize: "112px",
              }}
            >
              404
            </h1>
            <h2
              style={{
                fontSize: "32px",
              }}
            >
              НЕ НАЙДЕНО
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
